// 上海舱单(不知道业务逻辑，先把对应的axios留着，接口调通之后再删除axios4,axios5)
import {request} from "@/utils/request.js";
import {axiosVGMEnum} from "./enum";

const FEST_URL = axiosVGMEnum[process.env.VUE_APP_API_ENV];

// 保存
export function onSave(data) {
    return request(`/save`, "post", data, {
        baseURL: FEST_URL,
    });
}

// 修改
export function onUpdate(data) {
    return request(`/update`, "post", data, {
        baseURL: FEST_URL,
    });
}

// 请求列表信息
export function getList(param) {
    return request("/list", "get", param, {
        baseURL: FEST_URL,
    });
}
export function getAllUserVGMList(param) {
    return request("/getAllUserVGMList", "post", param, {
        baseURL: FEST_URL,
    });
}

//上海码头列表
export function getShWharfList(param) {
    return request("/wharf/list", "get", param, {
        baseURL: FEST_URL,
    });
}

//获取VGM详情
export function getDetailById(id) {
    return request(`/info/${id}`, "get", "", {
        baseURL: FEST_URL,
    });
}


// 删除
export function onDelete(param) {
    return request("/delete", "post", param, {
        baseURL: FEST_URL,
    });
}
// 删除VGM报文
export function onDeleteVGM(param) {
    return request("/deleteVGM", "post", param, {
        baseURL: FEST_URL,
    });
}

// 发送
export function onSend(param) {
    return request("/send", "post", param, {
        baseURL: FEST_URL,
    });
}

export function reSend(param) {
    return request("/resend", "post", param, {
        baseURL: FEST_URL,
    });
}

export function getCountNum(param) {
    return request("/getCountNum", "get", param, {
        baseURL: FEST_URL,
    });
}
export function saveRemark(param) {
    return request("/saveRemark", "post", param, {
        baseURL: FEST_URL,
    });
}

// 导出VGM发送记录excel文件
export function exportVgmHistoryExcelReq(data) {
    return request(`/exportExcel`, "post", data, {
        responseType: "blob",
        baseURL: FEST_URL,
    });
}

// 导出VGM发送记录excel文件
export function exportVgmBillExcelReq(data) {
    return request(`/exportBillExcel`, "post", data, {
        responseType: "blob",
        baseURL: FEST_URL,
    });
}

//导入VGMexcel文件
export function importExcelModule(data) {
    return request(
        "/importExcel",
        "post",
        data,
        { baseURL: FEST_URL },
        true,
    );
}

//获取vgm数据导入模版
export function downloadExcelModule(templateValue) {
    return request(`/download/vgmExcel/${templateValue}`, "get", "", {
        responseType: "blob",
        baseURL: FEST_URL,
    });
}


//获取vgm数据导入模版
export function changCompanyResquest(data) {
    return request(`/vgmChangeCompany`, "post", data, {
        baseURL: FEST_URL,
    });
}

export function vgmIsSend(param) {
    return request("/vgmIsSend", "post", param, {
        baseURL: FEST_URL,
    });
}

export function resetVGMSendStatus(param) {
    return request("/resetVGMSendStatus", "post", param, {
        baseURL: FEST_URL,
    });
}

/**
 * 获取舱单操作记录
 */
export function getVgmHistory(param) {
    return request(`/vgm/operator/list`, "get", param, {
    });
}
